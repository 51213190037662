.gotobutton {

    position: fixed;
    right: 1rem ;
    bottom: 2rem;
    font-size: .7rem;
    width: 1rem;
    height: 1rem;
    background: rgba(74, 74, 74, 0.201);
    border-radius: 50%;
    padding: 0.6rem .65rem;
    cursor: pointer;
    color: rgba(102, 102, 102, 0.745);
    animation-name: topup;
    animation: 5s all infinite;
    
    display: flex;
    justify-content: center;
    align-items: center;

}
.gotobutton:hover {

    position: fixed;
    right: 1rem ;
    bottom: 2rem;
    font-size: .7rem;
    background: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 0.6rem .65rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    /* transform: translate3d(0px, -4px , -5px) perspective(100px); */
    

}

@keyframes topup {
    
    0% {

        transform: translateY(0);
    }
    33% {

        transform: translateY(-15px);
    }
    66% {

        transform: translateY(15px);
    }
    0% {

        transform: translateY(0);
    }
}