
/* .centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
   */
  .h-button {
    background: rgba(24, 24, 24, 0.375);
    backdrop-filter: blur(3px);
    padding: .7rem 3rem;
    text-align: center;
    color: rgb(255, 255, 255);
    transition: all 1s ease;
    cursor: pointer;
  }

  .h-button:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
  .h-button span {
    display: inline-block;
    min-width: 0.3em;
    text-transform: uppercase;
    transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
    opacity: 0;
    transform: translate(0, -20px);
  }
  .h-button:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    left: 0;
    transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
    text-transform: uppercase;
    letter-spacing: 3.5px;
    opacity: 1;
    transform: translate(0, 0px);
  }
  .h-button:hover:before, .h-button:focus:before {
    opacity: 0;
    transform: translate(0, 20px);
  }
  .h-button:hover span, .h-button:focus span {
    opacity: 1;
    transform: translate(0, 0);
  }
  .h-button:hover span:nth-child(1), .h-button:focus span:nth-child(1) {
    transition-delay: 0.025s;
  }
  .h-button:hover span:nth-child(2), .h-button:focus span:nth-child(2) {
    transition-delay: 0.05s;
  }
  .h-button:hover span:nth-child(3), .h-button:focus span:nth-child(3) {
    transition-delay: 0.075s;
  }
  .h-button:hover span:nth-child(4), .h-button:focus span:nth-child(4) {
    transition-delay: 0.1s;
  }
  .h-button:hover span:nth-child(5), .h-button:focus span:nth-child(5) {
    transition-delay: 0.125s;
  }
  .h-button:hover span:nth-child(6), .h-button:focus span:nth-child(6) {
    transition-delay: 0.15s;
  }