@font-face {
    font-family: futuraheavy;
    src: url(./font/Futura\ Heavy\ font.ttf);
}



.minihead {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 100%;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
}

.minihead::-webkit-scrollbar {

    display: none;
}

.clsbar {

    position: fixed;
    top: 3rem;
    right: 3rem;
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 820px) {

    .clsbar {

        top: 2rem;
        right: 2rem;
    }
}



.bar4,
.bar5,
.bar6 {
    width: 35px;
    height: 5px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    transition: 0.4s;
}

.bar4 {
    transform: translate(0, 11px) rotate(-45deg);
    color: aliceblue;
}

.bar5 {
    opacity: 0;
}

.bar6 {
    transform: translate(0, -11px) rotate(45deg);
    color: aliceblue;
}

.navlinks ul {

    margin-top: 8rem;
    margin-left: -3rem;
}

.navlinks ul a {

    list-style: none;
    text-decoration: none;
    color: #fff;
    font-family: futuraheavy;
    font-size: 3rem;
    margin: 3rem auto;

}

.navlinks ul li {

    margin: 3rem;
    padding: auto 1rem;
    transition: .2s ease;
}

@media screen and (max-width: 820px) {

    .navlinks ul a {

        font-size: 2rem;
    }
}



.navlinks ul a:hover {

    color: rgba(0, 0, 0, 0.881);

}

.navlinks ul li:hover {

    background-color:#ABC9FF;
    backdrop-filter: blur(10px);
}

.dropbtn {
   
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #001a4d;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    color: aliceblue;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a li {
    color: rgb(255, 255, 255);
}

.dropdown-content a li:hover {

    color: #000;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: rgb(250, 250, 250);
    position: absolute;
    z-index: 100;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */